import React from 'react'
import { Tooltip } from '~/components/ui'

export default function ControlTooltip({
  children,
  text,
  side = 'left',
}: {
  children: React.ReactNode
  text: string
  side?: 'left' | 'top' | 'right' | 'bottom'
}) {
  return (
    <Tooltip.Provider delayDuration={300}>
      <Tooltip>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Content side={side}>
          <span>{text}</span>
        </Tooltip.Content>
      </Tooltip>
    </Tooltip.Provider>
  )
}
